import { Box, Grid, Typography } from '@material-ui/core'
import React, { useCallback, useState } from 'react'
import { FormWrapper } from './FormWrapper'
import { useCommonTranslations } from './Translation'
import { useAppContext } from '../layout/AppContext'
import { Alert } from '@material-ui/lab'
import { TextField } from '../formeditor/components/form/TextField'
import { useApolloClient, useMutation } from '@apollo/client/react/hooks'
import useCustomSnackbar from '../../lib/useSnackbar'
import {
  LoginFormEnterEmailDocument,
  LoginFormLoginDocument,
  LoginFormRequestLinkDocument,
} from './LoginForm.generated'
import { useRouter } from 'next/router'
import { MutationButton } from './MutationButton'
import { useCurrentConferenceName } from './useCurrent'
import { EmailExistence } from '../../../generated/graphql'
import { FormValuesSpy } from './FormDialog'

export const LoginForm = ({
  initialError,
  initialEmail,
  returnTo,
  onPasswordLogin,
}: {
  initialError?: string
  initialEmail?: string
  returnTo?: string
  onPasswordLogin?: () => void
}) => {
  const t = useCommonTranslations()
  const conferenceName = useCurrentConferenceName()
  const [existence, setExistence] = useState<EmailExistence | undefined>()
  const [email, setEmail] = useState<string | undefined>(initialEmail)
  const apolloClient = useApolloClient()
  const snackbar = useCustomSnackbar()
  const [error, setError] = useState<string | undefined>(initialError)
  const { setError: setAppContextError } = useAppContext()
  const { push } = useRouter()

  const [mutateOneTimeToken] = useMutation(LoginFormRequestLinkDocument, {})

  const mutate = useCallback(
    async (forPasswordReset: boolean) => {
      await mutateOneTimeToken({
        variables: { email: email || '', conferenceName, forPasswordReset, returnTo },
      })
      setExistence(EmailExistence.Secret)
    },
    [email, mutateOneTimeToken, conferenceName, returnTo]
  )

  return (
    <>
      {existence === EmailExistence.Secret ? (
        <>
          <Typography variant={'h6'}>{t.ui.login.login}</Typography>
          <Typography>{t.ui.login.pleaseCheckEmail}</Typography>
        </>
      ) : existence === EmailExistence.Password ? (
        <FormWrapper
          mutation={LoginFormLoginDocument}
          initialValues={{ conferenceName, email }}
          successSnackbar={false}
          onSubmit={(e) => {
            const conferenceUser = e.data?.loginParticipantPassword
            if (conferenceUser) {
              apolloClient?.reFetchObservableQueries()
              snackbar.showSuccess(t.login.success(conferenceUser.name))
              if (setAppContextError) {
                setAppContextError(undefined)
              }
              if (onPasswordLogin) {
                onPasswordLogin?.()
              }
              if (returnTo) {
                push(returnTo)
              }
            } else {
              snackbar.showError(t.login.wrongPassword)
            }
          }}
        >
          <Typography variant={'h6'}>{t.ui.login.login}</Typography>
          <TextField name={'email'} label={t.ui.login.email} fullWidth required autoFocus disabled />
          <TextField
            autoFocus
            required
            name={'password'}
            type={'password'}
            label={t.ui.login.password}
            fullWidth
          />
          <Box mt={2}>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <MutationButton variant={'outlined'} action={() => mutate(true)} fullWidth>
                  {t.login.forgotPassword}
                </MutationButton>
              </Grid>
              <Grid item md={6} xs={12}>
                <MutationButton variant={'outlined'} action={() => mutate(false)} fullWidth>
                  {t.login.oneTimeLoginLink}
                </MutationButton>
              </Grid>
            </Grid>
          </Box>
        </FormWrapper>
      ) : (
        <FormWrapper
          mutation={LoginFormEnterEmailDocument}
          initialValues={{ conferenceName, email, returnTo }}
          successSnackbar={false}
          onSubmit={(e) => {
            setExistence(e.data?.checkParticipant)
            if (existence === EmailExistence.None) {
              setError(t.ui.login.wrong)
            } else {
              setError(undefined)
            }
          }}
        >
          <FormValuesSpy onChange={(data) => setEmail(data.values?.email)} />
          <Box mb={4}>{error ? <Alert severity={'error'}>{error}</Alert> : null}</Box>
          <Typography variant={'h6'}>{t.ui.login.login}</Typography>
          <TextField name={'email'} label={t.ui.login.email} fullWidth required autoFocus />
        </FormWrapper>
      )}
    </>
  )
}
