import * as Types from '../../../generated/graphql';

import { ConferenceUserDataFragment, ShortConferenceDataFragment, FormDataFragment, FormFieldDataFragment, AbstractDisplayerDataFragment, ClientBaseDataFragment } from '../../data/fragments.generated';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { ConferenceUserDataFragmentDoc, ShortConferenceDataFragmentDoc, FormDataFragmentDoc, FormFieldDataFragmentDoc, AbstractDisplayerDataFragmentDoc, ClientBaseDataFragmentDoc } from '../../data/fragments.generated';
export type ConferenceUserQueryVariables = Types.Exact<{
  conferenceName: Types.Scalars['String'];
}>;


export type ConferenceUserQuery = (
  { __typename?: 'Query' }
  & { conferenceUser?: Types.Maybe<(
    { __typename?: 'ConferenceUser' }
    & ConferenceUserDataFragment
  )> }
);


export const ConferenceUserDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ConferenceUser"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"conferenceName"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"conferenceUser"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"conferenceName"},"value":{"kind":"Variable","name":{"kind":"Name","value":"conferenceName"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ConferenceUserData"}}]}}]}},...ConferenceUserDataFragmentDoc.definitions]} as unknown as DocumentNode<ConferenceUserQuery, ConferenceUserQueryVariables>;