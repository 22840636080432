import React, { ReactNode } from 'react'
import {
  Backdrop,
  Box,
  BoxProps,
  CircularProgress,
  Container,
  ContainerProps,
  Paper,
  Theme,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { createStyles } from '@material-ui/styles'
import { BottomText } from './BottomText'
import clsx from 'clsx'

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    img: { width: '100%' },
    container: {
      flex: 1,
      position: 'relative',
      zIndex: 10,
    },
    innerContainer: {
      position: 'relative',
    },
    paper: {
      position: 'relative',
    },
    withPaper: {
      padding: theme.spacing(6),
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(3),
      },
    },
    userLabel: {
      // [theme.breakpoints.up('lg')]: {
      //   position: 'absolute',
      //   paddingRight: theme.spacing(2),
      //   paddingLeft: theme.spacing(2),
      //   paddingTop: theme.spacing(2),
      //   top: 0,
      //   right: 0,
      //   width: '100%',
      // },
      // [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      paddingBottom: theme.spacing(2),
      // },
      // textAlign: 'center',
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    targetLogo: {
      height: '70px',
      maxWidth: '100%',
      position: 'relative',
      [theme.breakpoints.down('xs')]: {
        marginBottom: '12px',
      },
    },
    iccaLogo: {
      position: 'relative',
      height: '100px',
      width: '61px',
      [theme.breakpoints.down('xs')]: {
        height: '60px',
        width: '37px',
      },
    },
    noFlip: {
      flip: false,
      textAlign: 'left',
      direction: 'ltr',
    },
    alignLeft: {
      flip: false,
      textAlign: 'left',
    },
    bottomText: {
      textIndent: '-61px',
      [theme.breakpoints.down('xs')]: {
        textIndent: '-35px',
      },
    },
    loginLink: {
      margin: 0,
    },
  })
)

export type RegisterLayoutProps = {
  loading?: boolean
  children: ReactNode
  boxProps?: BoxProps
  rtl?: boolean
  maxWidth?: ContainerProps['maxWidth']
  withoutPaper?: boolean
}

export const RegisterLayout = React.memo(
  ({ children, loading = false, boxProps, maxWidth = 'md', withoutPaper = false }: RegisterLayoutProps) => {
    const classes = useStyles()
    const PaperComp = withoutPaper ? Box : Paper

    return (
      <>
        <Box className={classes.container} {...boxProps} width={'100%'}>
          <Container maxWidth={maxWidth} component="main" className={classes.innerContainer}>
            <PaperComp className={clsx(classes.paper, !withoutPaper && classes.withPaper)}>
              {children}
            </PaperComp>
            <BottomText />
          </Container>
        </Box>

        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    )
  }
)
