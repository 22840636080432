import * as Types from '../../../generated/graphql';

import { ConferenceUserDataFragment, ShortConferenceDataFragment, FormDataFragment, FormFieldDataFragment, AbstractDisplayerDataFragment, ClientBaseDataFragment } from '../../data/fragments.generated';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { ConferenceUserDataFragmentDoc, ShortConferenceDataFragmentDoc, FormDataFragmentDoc, FormFieldDataFragmentDoc, AbstractDisplayerDataFragmentDoc, ClientBaseDataFragmentDoc } from '../../data/fragments.generated';
export type LoginFormEnterEmailMutationVariables = Types.Exact<{
  email: Types.Scalars['String'];
  conferenceName: Types.Scalars['String'];
  returnTo?: Types.Maybe<Types.Scalars['String']>;
}>;


export type LoginFormEnterEmailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'checkParticipant'>
);

export type LoginFormRequestLinkMutationVariables = Types.Exact<{
  email: Types.Scalars['String'];
  conferenceName: Types.Scalars['String'];
  forPasswordReset: Types.Scalars['Boolean'];
  returnTo?: Types.Maybe<Types.Scalars['String']>;
}>;


export type LoginFormRequestLinkMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'requestToken'>
);

export type LoginFormLoginMutationVariables = Types.Exact<{
  conferenceName: Types.Scalars['String'];
  email: Types.Scalars['String'];
  password: Types.Scalars['String'];
}>;


export type LoginFormLoginMutation = (
  { __typename?: 'Mutation' }
  & { loginParticipantPassword?: Types.Maybe<(
    { __typename?: 'ConferenceUser' }
    & ConferenceUserDataFragment
  )> }
);


export const LoginFormEnterEmailDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"LoginFormEnterEmail"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"email"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"conferenceName"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"returnTo"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"checkParticipant"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"email"},"value":{"kind":"Variable","name":{"kind":"Name","value":"email"}}},{"kind":"Argument","name":{"kind":"Name","value":"conferenceName"},"value":{"kind":"Variable","name":{"kind":"Name","value":"conferenceName"}}},{"kind":"Argument","name":{"kind":"Name","value":"returnTo"},"value":{"kind":"Variable","name":{"kind":"Name","value":"returnTo"}}}]}]}}]} as unknown as DocumentNode<LoginFormEnterEmailMutation, LoginFormEnterEmailMutationVariables>;
export const LoginFormRequestLinkDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"LoginFormRequestLink"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"email"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"conferenceName"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"forPasswordReset"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"Boolean"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"returnTo"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"requestToken"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"conferenceName"},"value":{"kind":"Variable","name":{"kind":"Name","value":"conferenceName"}}},{"kind":"Argument","name":{"kind":"Name","value":"email"},"value":{"kind":"Variable","name":{"kind":"Name","value":"email"}}},{"kind":"Argument","name":{"kind":"Name","value":"forPasswordReset"},"value":{"kind":"Variable","name":{"kind":"Name","value":"forPasswordReset"}}},{"kind":"Argument","name":{"kind":"Name","value":"returnTo"},"value":{"kind":"Variable","name":{"kind":"Name","value":"returnTo"}}}]}]}}]} as unknown as DocumentNode<LoginFormRequestLinkMutation, LoginFormRequestLinkMutationVariables>;
export const LoginFormLoginDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"LoginFormLogin"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"conferenceName"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"email"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"password"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"loginParticipantPassword"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"conferenceName"},"value":{"kind":"Variable","name":{"kind":"Name","value":"conferenceName"}}},{"kind":"Argument","name":{"kind":"Name","value":"email"},"value":{"kind":"Variable","name":{"kind":"Name","value":"email"}}},{"kind":"Argument","name":{"kind":"Name","value":"password"},"value":{"kind":"Variable","name":{"kind":"Name","value":"password"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ConferenceUserData"}}]}}]}},...ConferenceUserDataFragmentDoc.definitions]} as unknown as DocumentNode<LoginFormLoginMutation, LoginFormLoginMutationVariables>;