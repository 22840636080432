import { ButtonProps } from '@material-ui/core'
import React, { useCallback, useState } from 'react'
import { SpinningButton } from './SubmitButton'

export const MutationButton = ({ action, ...props }: { action: () => Promise<any> } & ButtonProps) => {
  const [loading, setLoading] = useState(false)
  const doAction = useCallback(async () => {
    setLoading(true)
    await action()
    setLoading(false)
  }, [setLoading, action])

  return <SpinningButton loading={loading} disabled={loading} onClick={doAction} {...props} />
}
