import { Dialog, DialogContent } from '@material-ui/core'
import { LoginForm } from './LoginForm'
import React from 'react'

export const LoginDialog = ({
  open,
  onClose,
  initialEmail,
  returnTo,
}: {
  open: boolean
  onClose: () => void
  initialEmail?: string
  returnTo?: string
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth={'sm'} fullWidth>
      <DialogContent>
        <LoginForm initialEmail={initialEmail} returnTo={returnTo} onPasswordLogin={onClose} />
      </DialogContent>
    </Dialog>
  )
}
