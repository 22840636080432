import * as Types from '../../../generated/graphql';

import { ConferenceUserDataFragment, ShortConferenceDataFragment, FormDataFragment, FormFieldDataFragment, AbstractDisplayerDataFragment, ClientBaseDataFragment } from '../../data/fragments.generated';
import { RegistrationFormFragment } from '../register/RegisterForm.generated';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { ConferenceUserDataFragmentDoc, ShortConferenceDataFragmentDoc, FormDataFragmentDoc, FormFieldDataFragmentDoc, AbstractDisplayerDataFragmentDoc, ClientBaseDataFragmentDoc } from '../../data/fragments.generated';
import { RegistrationFormFragmentDoc } from '../register/RegisterForm.generated';
export type UserLayoutQueryVariables = Types.Exact<{
  conferenceName: Types.Scalars['String'];
}>;


export type UserLayoutQuery = (
  { __typename?: 'Query' }
  & { conferenceUser?: Types.Maybe<(
    { __typename?: 'ConferenceUser' }
    & ConferenceUserDataFragment
  )>, conference?: Types.Maybe<(
    { __typename?: 'Conference' }
    & UserLayoutConferenceFragment
  )> }
);

export type UserLayoutConferenceFragment = (
  { __typename?: 'Conference' }
  & { forms: Array<(
    { __typename?: 'Form' }
    & RegistrationFormFragment
  )> }
  & ShortConferenceDataFragment
);

export type LogoutParticipantMutationVariables = Types.Exact<{
  conferenceName: Types.Scalars['String'];
}>;


export type LogoutParticipantMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'logoutParticipant'>
);

export const UserLayoutConferenceFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"UserLayoutConference"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Conference"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ShortConferenceData"}},{"kind":"Field","name":{"kind":"Name","value":"forms"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"RegistrationForm"}}]}}]}},...ShortConferenceDataFragmentDoc.definitions,...RegistrationFormFragmentDoc.definitions]} as unknown as DocumentNode<UserLayoutConferenceFragment, unknown>;
export const UserLayoutDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"UserLayout"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"conferenceName"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"conferenceUser"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"conferenceName"},"value":{"kind":"Variable","name":{"kind":"Name","value":"conferenceName"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ConferenceUserData"}}]}},{"kind":"Field","name":{"kind":"Name","value":"conference"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"shortName"},"value":{"kind":"Variable","name":{"kind":"Name","value":"conferenceName"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"UserLayoutConference"}}]}}]}},...ConferenceUserDataFragmentDoc.definitions,...UserLayoutConferenceFragmentDoc.definitions]} as unknown as DocumentNode<UserLayoutQuery, UserLayoutQueryVariables>;
export const LogoutParticipantDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"LogoutParticipant"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"conferenceName"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"logoutParticipant"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"conferenceName"},"value":{"kind":"Variable","name":{"kind":"Name","value":"conferenceName"}}}]}]}}]} as unknown as DocumentNode<LogoutParticipantMutation, LogoutParticipantMutationVariables>;