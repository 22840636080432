import { useCommonTranslations } from '../../common/Translation'
import { useIsInDndContext } from '../../form/FormEditorDndContext'
import { useApolloClient, useMutation, useQuery } from '@apollo/client/react/hooks'
import { bindPopover, usePopupState } from 'material-ui-popup-state/hooks'
import { Box, Link as MuiLink } from '@material-ui/core'
import React, { MouseEvent, ReactNode, useCallback, useMemo } from 'react'
import { LoginDialog } from '../../common/LoginDialog'
import { SpinningButton } from '../../common/SubmitButton'
import { ConferenceUserDocument } from '../FormRegisterContext.generated'
import { TypographyTypeMap } from '@material-ui/core/Typography/Typography'
import Link from 'next/link'
import { useCurrentConferenceName } from '../../common/useCurrent'
import useCustomSnackbar from '../../../lib/useSnackbar'
import { useConferenceRegisterContext } from '../FormRegisterContext'
import { LogoutParticipantDocument } from '../../layout/UserLayout.generated'

export function useConferenceUserData() {
  const conferenceName = useCurrentConferenceName()
  const inDnd = useIsInDndContext()
  const { data } = useQuery(ConferenceUserDocument, {
    skip: !conferenceName || inDnd,
    variables: { conferenceName },
    fetchPolicy: 'cache-and-network',
  })
  return data
}

function useLogoutMutation(conferenceName: string) {
  const snackbar = useCustomSnackbar()
  const [logoutMutation, args] = useMutation(LogoutParticipantDocument, {
    variables: { conferenceName },
    refetchQueries: [
      {
        query: ConferenceUserDocument,
        variables: { conferenceName },
      },
    ],
    awaitRefetchQueries: true,
  })
  const t = useCommonTranslations()

  const apolloClient = useApolloClient()
  const doLogout = useCallback(async () => {
    await logoutMutation()
    snackbar.showSuccess(t.login.logoutSuccess)
    await apolloClient.reFetchObservableQueries()
  }, [logoutMutation, snackbar, t.login.logoutSuccess, apolloClient])

  return useMemo(() => [doLogout, args] as const, [doLogout, args])
}

export const CurrentUserLabel = ({
  label,
  color,
  returnTo,
  alwaysShowLabel,
}: {
  label?: ReactNode
  color?: TypographyTypeMap['props']['color']
  returnTo?: string
  alwaysShowLabel?: boolean
}) => {
  const t = useCommonTranslations()
  const conferenceName = useCurrentConferenceName()
  const { user } = useConferenceRegisterContext()
  const [logout, { loading }] = useLogoutMutation(conferenceName)
  const popupState = usePopupState({ variant: 'popover', popupId: 'loginLink' })

  return (
    <>
      {!alwaysShowLabel && user?.name ? (
        <>
          {t.ui.login.loggedInAs(user.name)}{' '}
          <SpinningButton onClick={() => logout()} variant={'text'} size={'small'} loading={loading}>
            {t.ui.login.logout}
          </SpinningButton>
        </>
      ) : (
        <>
          <Link href={returnTo || '#'} passHref>
            <MuiLink
              color={color}
              // variant={'h5'}
              onClick={(event: MouseEvent<any>) => {
                if (!user?.name) {
                  event.preventDefault()
                  popupState.open(event)
                }
              }}
            >
              {label || (
                <Box display={'inline-flex'} alignItems={'center'} flexDirection={'row'}>
                  {t.login.alreadyRegisteredQuestion}
                </Box>
              )}
            </MuiLink>
          </Link>
          <LoginDialog {...bindPopover(popupState)} returnTo={returnTo} />
        </>
      )}
    </>
  )
}
