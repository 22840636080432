import { makeStyles } from '@material-ui/core/styles'
import { createStyles } from '@material-ui/styles'
import { useCurrentConferenceNameAllowEmpty } from '../../common/useCurrent'
import { useIsInDndContext } from '../../form/FormEditorDndContext'
import { useConferenceRegisterContext } from '../FormRegisterContext'
import { Box, Typography } from '@material-ui/core'
import Image from 'next/image'
import TargetLogo from '../../../../images/target-logo.png'
import IccaLogo from '../../../../images/iccalogo.png'
import Link from 'next/link'
import { routes } from '../../../routes'
import React from 'react'

const useStyles = makeStyles((theme) =>
  createStyles({
    targetLogo: {
      height: '70px',
      maxWidth: '100%',
      position: 'relative',
      [theme.breakpoints.down('xs')]: {
        marginBottom: '12px',
      },
    },
    iccaLogo: {
      position: 'relative',
      height: '100px',
      width: '61px',
      [theme.breakpoints.down('xs')]: {
        height: '60px',
        width: '37px',
      },
    },
    noFlip: {
      flip: false,
      textAlign: 'left',
      direction: 'ltr',
    },
    bottomText: {
      textIndent: '-61px',
      [theme.breakpoints.down('xs')]: {
        textIndent: '-35px',
      },
    },
  })
)
export const BottomText = () => {
  const conferenceName = (useCurrentConferenceNameAllowEmpty() || '') as string
  const inDnd = useIsInDndContext()
  const { settings: conferenceSettings } = useConferenceRegisterContext()
  const email = conferenceSettings?.fromEmail || 'target@target-conferences.com'
  const classes = useStyles()

  return inDnd ? null : (
    <>
      <Box textAlign={'center'} mt={4} mb={1} p={2}>
        <a href={'https://www.target-conferences.com'} target={'_blank'} rel="noreferrer">
          <div className={classes.targetLogo}>
            <Image src={TargetLogo} alt={'Target Conferences Logo'} layout={'fill'} objectFit={'contain'} />
          </div>
        </a>
      </Box>
      <Box display={'flex'} flexDirection={'row'} alignItems={'center'} className={classes.noFlip}>
        <Box className={classes.iccaLogo}>
          <Image src={IccaLogo} alt={'ICCA Member logo'} layout={'fill'} />
        </Box>
        <Box dir={'ltr'} width={'100%'} textAlign={'center'}>
          <Box className={classes.bottomText}>
            <Typography variant={'subtitle1'}>
              <Box>P.O. Box 51227, Tel Aviv 6713818, Israel</Box>
              <Box>Tel: +972 3 5175150, Fax: +972 3 5175155</Box>
              <Box>
                e-mail: <a href={'mailto:' + email}>{email}</a>
              </Box>
            </Typography>
          </Box>
        </Box>
      </Box>
      <Typography variant={'overline'}>
        <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} mt={1}>
          <Box mr={1} ml={1}>
            <Link {...routes.terms(conferenceName)}>Terms and Conditions</Link>
          </Box>
          <Box ml={1} mr={1}>
            <Link {...routes.privacyPolicy(conferenceName)}>Privacy Policy</Link>
          </Box>
        </Box>
      </Typography>
    </>
  )
}
